import { LocaleObject } from './locale';

// **********************************************************************************************
// Forms
// **********************************************************************************************
export enum ServerFormSectionType {
    UNIQUE = 'unique',
    MULTIPLE = 'multiple',
}

export enum ServerFormConditionRequirementType {
    EQUAL = 'equals',
    CONTAINS = 'contains',
    START_WITH = 'start_with',
    END_WITH = 'end_with',
    GREATER_THAN = 'greater_than',
    LOWER_THAN = 'lower_than',
    AFTER_DATE = 'after_date',
    BEFORE_DATE = 'before_date',
    NOT_EMPTY = 'not_empty',
}

export enum ServerFormConditionActionType {
    SHOW = 'show', // show question
    HIDE = 'hide', // hide question/group
}

export type ServerForm = {
    id: string;
    title: LocaleObject;
    sections: Array<ServerFormSection & { index: number; page: number; sectionType: ServerFormSectionType }>;
    conditions: Array<ServerFormCondition>;
    report: {
        fields: Array<ServerFormReportField>;
    };
};

export type ServerFormPayload = {
    title: LocaleObject;
    sections: Array<{ id: string; index: number; page: number; sectionType: ServerFormSectionType }>;
};

// **********************************************************************************************
// Sections
// **********************************************************************************************

export type ServerFormSection = {
    id: string;
    name: string;
    title: LocaleObject;
    description: LocaleObject;
    questions: Array<ServerFormQuestion & { index: number }>;
};

export type ServerFormSectionPayload = {
    name: string;
    title: LocaleObject;
    description: LocaleObject;
    questions: Array<{ id: string; index: number }>;
};

// **********************************************************************************************
// Questions
// **********************************************************************************************
export enum ServerFormQuestionInputType {
    TEXT_INPUT = 'text_input',
    NUMBER_INPUT = 'number_input',
    DATE_INPUT = 'date_input',
    SELECT_INPUT = 'select_input',
}

type ServerFormQuestionBase = {
    id: string;
    name: string;
    label: LocaleObject;
    placeholder: LocaleObject;
    tooltip: LocaleObject;
};

type ServerFormQuestionTextInput = ServerFormQuestionBase & {
    inputType: ServerFormQuestionInputType.TEXT_INPUT;
};

type ServerFormQuestionNumberInput = ServerFormQuestionBase & {
    inputType: ServerFormQuestionInputType.NUMBER_INPUT;
};

type ServerFormQuestionDateInput = ServerFormQuestionBase & {
    inputType: ServerFormQuestionInputType.DATE_INPUT;
};

type ServerFormQuestionSelectInput = ServerFormQuestionBase & {
    inputType: ServerFormQuestionInputType.SELECT_INPUT;
    options: Array<{
        label: LocaleObject;
        value: string;
    }>;
};

export type ServerFormQuestion =
    | ServerFormQuestionTextInput
    | ServerFormQuestionNumberInput
    | ServerFormQuestionDateInput
    | ServerFormQuestionSelectInput;

export type ServerFormQuestionPayload = {
    name: string;
    label: LocaleObject;
    placeholder: LocaleObject;
    tooltip: LocaleObject;
    inputType: ServerFormQuestionInputType;
    options?: Array<{ label: LocaleObject; value?: string }>;
};

// **********************************************************************************************
// Conditions
// **********************************************************************************************

export type ServerFormCondition = {
    source: {
        section: {
            id: string;
            name: string;
        };
        question: {
            id: string;
            name: string;
        };
    };
    target: {
        section: {
            id: string;
            name: string;
        };
        question?: {
            id: string;
            name: string;
        };
    };
    requirement: {
        requirementType: ServerFormConditionRequirementType;
        value: string | number;
    };
    action: {
        actionType: ServerFormConditionActionType;
    };
};

export type ServerFormConditionPayload = {
    source: {
        sectionId: string;
        questionId: string;
    };
    target: {
        sectionId: string;
        questionId?: string;
    };
    requirement: {
        requirementType: ServerFormConditionRequirementType;
        value: string | number;
    };
    action: {
        actionType: ServerFormConditionActionType;
    };
};

// **********************************************************************************************
// Reports
// **********************************************************************************************

export type ServerFormReportField = {
    section: {
        id: string;
        name: string;
    };
    question: {
        id: string;
        name: string;
        inputType: ServerFormQuestionInputType;
        options?: Array<{
            label: LocaleObject;
            value: string;
            index: number;
        }>;
    };
    index: number;
    settings: {
        useFilter: boolean;
    };
};

export type ServerFormReportFieldPayload = {
    sectionId: string;
    questionId: string;
    useFilter: boolean;
    index: number;
};
