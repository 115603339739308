import React from 'react';
import { CaseReport } from '../../../../../types';
import { Container, DateRange, Search, Select } from './ReportActions-styles';
import { DateRangeInput, MultiSelectInput, TextInput } from '../../../../../components';
import { ServerFormQuestionInputType, ServerFormReportField } from '../../../../../types/forms';
import { useLocale } from '../../../../../hooks';
import { formatLocalizedText } from '../../../../../tools';
import { TableSelectFilter, TableDateRangeFilter, DateRange as DateRangeType } from '../../Report';

type Props = {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    selectFilters: Array<TableSelectFilter>;
    onChangeSelectFilter: (field: ServerFormReportField, values: Array<string>) => void;
    dateRangeFilters: Array<TableDateRangeFilter>;
    onChangeDateRangeFilter: (field: ServerFormReportField, values: DateRangeType) => void;
    report: CaseReport;
};

const ReportActions = ({
    report,
    searchTerm,
    setSearchTerm,
    onChangeSelectFilter,
    selectFilters,
    onChangeDateRangeFilter,
    dateRangeFilters,
}: Props) => {
    const locale = useLocale();

    const renderField = (field: ServerFormReportField, index: number) => {
        if (field.question.inputType === ServerFormQuestionInputType.SELECT_INPUT) {
            const filter = selectFilters.find(
                (i) => i.sectionId === field.section.id && i.questionId === field.question.id
            )!;
            return (
                <Select key={field.index}>
                    <MultiSelectInput
                        isSearchable={false}
                        label={field.question.name}
                        key={index}
                        value={filter.values}
                        options={[
                            ...field.question.options!.map((option) => ({
                                label: formatLocalizedText(option.label, locale),
                                value: option.value,
                            })),
                        ]}
                        onChange={(values) => onChangeSelectFilter(field, values)}
                    />
                </Select>
            );
        }

        if (field.question.inputType === ServerFormQuestionInputType.DATE_INPUT) {
            const filter = dateRangeFilters.find(
                (i) => i.sectionId === field.section.id && i.questionId === field.question.id
            )!;
            return (
                <DateRange key={field.index}>
                    <DateRangeInput
                        label={field.question.name}
                        onChange={(values) => onChangeDateRangeFilter(field, values)}
                        value={filter.values}
                    />
                </DateRange>
            );
        }

        return <React.Fragment key={field.index}></React.Fragment>;
    };

    return (
        <Container>
            <Search>
                <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    name="searchTerm"
                    placeholder="Search..."
                />
            </Search>

            {report.fields
                .filter((field) => field.settings.useFilter === true)
                .map((field, index) => renderField(field, index))}
        </Container>
    );
};

export default ReportActions;
