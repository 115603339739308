import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.box.spacing.lg};
`;

export const Search = styled.div`
    width: 40%;
    max-width: 30rem;
`;

export const Select = styled.div`
    width: 20%;
    max-width: 27rem;
    min-width: 23rem;
`;

export const DateRange = styled.div`
    width: 40%;
    max-width: 36rem;
    min-width: 33rem;
`;
