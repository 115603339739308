import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePromises } from '../../../hooks';
import { APIService } from '../../../services';
import { LocalizedText, PageContainer, PageHeader, PageHeaderTitle, Spinner } from '../../../components';
import { TbReportSearch } from 'react-icons/tb';
import { ReportActions, ReportTable } from './components';
import { ServerFormQuestionInputType } from '../../../types';
import { ServerFormReportField } from '../../../types/forms';

export type DateRange = {
    startDate: undefined | Date;
    endDate: undefined | Date;
};

export type TableSelectFilter = {
    sectionId: string;
    questionId: string;
    options: Array<string>;
    values: Array<string>;
};

export type TableDateRangeFilter = {
    sectionId: string;
    questionId: string;
    values: DateRange;
};

const Report = () => {
    const params = useParams();
    const [searchTerm, setSearchTerm] = useState('');

    const [_, [report]] = usePromises(() => APIService.forms().getFormsReport(params.form_id as string));
    const [isInitialized, setIsInitialized] = useState(false);

    const [selectFilters, setSelectFilters] = useState<Array<TableSelectFilter>>([]);
    const [dateRangeFilters, setDateRangeFilters] = useState<Array<TableDateRangeFilter>>([]);

    useEffect(() => {
        if (report.isLoading !== true && report.data != null) {
            const initialSelectFilters = report.data.fields
                .filter(
                    (field) =>
                        field.settings.useFilter === true &&
                        field.question.inputType === ServerFormQuestionInputType.SELECT_INPUT
                )
                .map((field) => ({
                    sectionId: field.section.id,
                    questionId: field.question.id,
                    options: field.question.options!.map((option) => option.value),
                    values: field.question.options!.map((option) => option.value),
                }));

            const initialDateRangeFilters = report.data.fields
                .filter(
                    (field) =>
                        field.settings.useFilter === true &&
                        field.question.inputType === ServerFormQuestionInputType.DATE_INPUT
                )
                .map((field) => ({
                    sectionId: field.section.id,
                    questionId: field.question.id,
                    values: { startDate: undefined, endDate: undefined },
                }));

            setSelectFilters(initialSelectFilters);
            setDateRangeFilters(initialDateRangeFilters);
            setIsInitialized(true);
        }
    }, [report]);

    const onChangeSelectFilter = (field: ServerFormReportField, values: Array<string>) => {
        setSelectFilters((prev) =>
            prev.map((prevFilter) =>
                prevFilter.sectionId === field.section.id && prevFilter.questionId === field.question.id
                    ? { ...prevFilter, values: values }
                    : prevFilter
            )
        );
    };

    const onChangeDateRangeFilter = (field: ServerFormReportField, values: DateRange) => {
        setDateRangeFilters((prev) =>
            prev.map((prevFilter) =>
                prevFilter.sectionId === field.section.id && prevFilter.questionId === field.question.id
                    ? { ...prevFilter, values: values }
                    : prevFilter
            )
        );
    };

    if (isInitialized !== true) {
        return (
            <PageContainer>
                <Spinner />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <PageHeader>
                <PageHeaderTitle>
                    <TbReportSearch />
                    <LocalizedText text={report.data.title} />
                </PageHeaderTitle>
                <ReportActions
                    report={report.data}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    selectFilters={selectFilters}
                    onChangeSelectFilter={onChangeSelectFilter}
                    dateRangeFilters={dateRangeFilters}
                    onChangeDateRangeFilter={onChangeDateRangeFilter}
                />
                <ReportTable
                    report={report.data}
                    searchTerm={searchTerm}
                    selectFilters={selectFilters}
                    dateRangeFilters={dateRangeFilters}
                />
            </PageHeader>
        </PageContainer>
    );
};

export default Report;
