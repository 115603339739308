import React, { useMemo, useState } from 'react';
import { ServerForm, ServerFormQuestionInputType } from '../../../../../types';
import { usePromises } from '../../../../../hooks';
import { DraggableList, Modal, ModalFooter, ModalSize, SelectInput } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { List, AddField, AddFieldText, Field, RemoveField } from './UpdateFormReportModal-styles';
import { ServerFormReportFieldPayload } from '../../../../../types/forms';
import { APIService } from '../../../../../services';
import { AiOutlineCloseCircle } from 'react-icons/ai';

type Props = {
    onClose: () => void;
    refreshFormsList: () => Promise<void>;
    selectedForm: ServerForm;
};

export type ReportField = {
    value: string;
    label: string;
    inputType: ServerFormQuestionInputType;
    isFilter: boolean;
};

const emptyField = {
    value: '',
    label: '',
    isFilter: false,
    inputType: ServerFormQuestionInputType.TEXT_INPUT,
};

const getInitialFields = (form: ServerForm): Array<ReportField> => {
    if (form.report.fields.length === 0) {
        return [emptyField];
    }

    return form.report.fields.map((field) => ({
        value: `${field.section.id}_${field.question.id}`,
        label: `[${field.section.name}][${field.question.name}]`,
        inputType: field.question.inputType,
        isFilter: field.settings.useFilter,
    }));
};

const UpdateFormReportModal = ({ onClose, refreshFormsList, selectedForm }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();
    const [fields, setFields] = useState<Array<ReportField>>(getInitialFields(selectedForm));

    const availableFields = useMemo(() => {
        const list: Array<ReportField> = [];
        for (const section of selectedForm.sections) {
            for (const question of section.questions) {
                list.push({
                    value: `${section.id}_${question.id}`,
                    label: `[${section.name}][${question.name}]`,
                    inputType: question.inputType,
                    isFilter: false,
                });
            }
        }
        return list;
    }, [selectedForm]);

    const submitReport = async () => {
        await executePromise(async () => {
            const list: Array<ServerFormReportFieldPayload> = [];
            fields.forEach((field, index) => {
                const [sectionId, questionId] = field.value.split('_');

                if (sectionId.length > 0 && questionId.length > 0) {
                    list.push({
                        sectionId,
                        questionId,
                        useFilter: field.isFilter,
                        index: index,
                    });
                }
            });
            await APIService.forms().updateFormReport(selectedForm.id, { fields: list });
            await refreshFormsList();
            onClose();
        });
    };

    const handleAddField = () => {
        setFields((prev) => [...prev, emptyField]);
    };

    const handleUpdateField = (field: ReportField, index: number) => {
        setFields((prev) => prev.map((prevItem, prevIndex) => (prevIndex === index ? field : prevItem)));
    };

    const handleRemoveField = (index: number) => {
        if (fields.length === 1) {
            setFields([emptyField]);
        } else {
            setFields((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
        }
    };

    const renderField = (field: ReportField, index: number) => {
        const list = availableFields.filter((available) =>
            available.value === field.value ? true : !fields.some((item) => item.value === available.value)
        );

        const onChangeQuestion = (value: string) => {
            const selectedField = availableFields.find((item) => item.value === value)!;
            handleUpdateField(selectedField, index);
        };

        return (
            <Field>
                <SelectInput
                    options={list}
                    onChange={(value) => onChangeQuestion(value)}
                    value={field.value}
                    leadingIcon={
                        <RemoveField onClick={() => handleRemoveField(index)}>
                            <AiOutlineCloseCircle />
                        </RemoveField>
                    }
                />
                <SelectInput
                    isDisabled={field.inputType === ServerFormQuestionInputType.TEXT_INPUT}
                    label={t('generic.filter')}
                    options={[
                        { label: t('generic.yes'), value: true },
                        { label: t('generic.no'), value: false },
                    ]}
                    value={field.isFilter}
                    onChange={(value) => handleUpdateField({ ...field, isFilter: value }, index)}
                />
            </Field>
        );
    };

    return (
        <Modal
            title={t('forms.main.modals.reports.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <List>
                <DraggableList
                    list={fields}
                    renderListItem={renderField}
                    getItemId={(field) => field.value}
                    onChange={(updatedFields) => setFields(updatedFields)}
                />
                {fields[fields.length - 1].value.length > 0 && (
                    <AddField>
                        <AddFieldText onClick={handleAddField}>
                            {t('forms.main.modals.reports.add_column')}
                        </AddFieldText>
                    </AddField>
                )}
            </List>
            <ModalFooter
                confirmationLabel={t('generic.save')}
                onClick={submitReport}
                secondary={{ onClick: onClose }}
            />
        </Modal>
    );
};

export default UpdateFormReportModal;
