import styled from 'styled-components';
import { InputMode } from '../Inputs-types';

export const Container = styled.div<{
    mode?: InputMode;
}>`
    width: 100%;
    height: ${({ theme }) => theme.config.input.height.default}px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    z-index: 2;
    background: ${({ theme }) => theme.colors.surface.main};
    font-size: ${({ theme }) => theme.font.size.lg};
    border-radius: 0.8rem;

    ${({ mode, theme }) => {
        if (mode === InputMode.STAND_ALONE) {
            return {
                borderRadius: '0.3rem',
                height: `${theme.config.input.height.small}px`,
                '.input-container__trailing-icon': {
                    height: `${theme.config.input.height.small}px`,
                },
            };
        }
    }}
`;

export const Label = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

export const TrailingIconContainer = styled.div`
    width: 3rem;
    min-width: 3rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    svg {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const InputInnerLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding-left: ${({ theme }) => theme.box.spacing.sm};
    min-width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    line-height: 1.4;
`;

export const DateRange = styled.div`
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    width: 100%;
    z-index: 3;

    .rdrDateRangePickerWrapper {
        box-shadow: ${({ theme }) => theme.box.shadow.main};
        background: ${({ theme }) => theme.colors.surface.main};
    }
`;

export const Clear = styled.div`
    position: relative;
    z-index: 4;
    cursor: pointer;
`;
