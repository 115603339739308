import React from 'react';
import {
    LocalizedText,
    PageContainer,
    PageHeader,
    PageHeaderTitle,
    Table,
    TableColumnConfig,
} from '../../../components';
import { useLocale, usePromises, useTable } from '../../../hooks';
import { APIService } from '../../../services';
import { TbReportSearch } from 'react-icons/tb';
import { LocaleObject } from '../../../types';
import { useTranslation } from 'react-i18next';
import { formatLocalizedText } from '../../../tools';
import { Routes } from '../../../routes';
import { Report, ReportLink } from './ReportsList-styles';

const ReportsList = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [promises, [reports]] = usePromises(() => APIService.forms().getFormsReportsList());

    const columns: Array<TableColumnConfig<{ id: string; title: LocaleObject }>> = [
        {
            id: 'title',
            headerTitle: t('generic.title'),
            width: '1fr',
            getValue: (report) => formatLocalizedText(report.title, locale),
            getDisplayedValue: (report) => (
                <Report>
                    <TbReportSearch />
                    <ReportLink to={`/${Routes.REPORTS}/${report.id}`}>
                        <LocalizedText text={report.title} />
                    </ReportLink>
                </Report>
            ),
        },
    ];

    const table = useTable(reports.data, columns);

    return (
        <PageContainer error={promises.error} clearError={promises.clearError}>
            <PageHeader>
                <PageHeaderTitle>
                    <TbReportSearch />
                    Reports
                </PageHeaderTitle>
            </PageHeader>
            <Table {...table} columns={table.displayedColumns} isLoading={reports.isLoading} />;
        </PageContainer>
    );
};

export default ReportsList;
