import React, { useMemo } from 'react';
import { CaseReport, CaseReportForm, ServerFormQuestionInputType } from '../../../../../types';
import { useLocale, useTable } from '../../../../../hooks';
import { Table, TableColumnConfig } from '../../../../../components';
import { Routes } from '../../../../../routes';
import { CaseInternalIdContainer } from './ReportTable-styles';
import { AiFillFolder } from 'react-icons/ai';
import { formatLocalizedText } from '../../../../../tools';
import moment from 'moment';
import { TableSelectFilter, TableDateRangeFilter } from '../../Report';
import { useTranslation } from 'react-i18next';

type Props = {
    report: CaseReport;
    searchTerm: string;
    selectFilters: Array<TableSelectFilter>;
    dateRangeFilters: Array<TableDateRangeFilter>;
};

const ReportTable = ({ report, searchTerm, selectFilters, dateRangeFilters }: Props) => {
    const locale = useLocale();
    const { t } = useTranslation();

    const tableFilters = useMemo(() => {
        const currentFilters = {
            dependencies: [...selectFilters, ...dateRangeFilters],
            filterFunctions: [
                ...selectFilters.map((filter) => {
                    return (caseReport: CaseReportForm) => {
                        if (filter.options.length === filter.values.length) {
                            return true;
                        }
                        const caseReportValue = caseReport.values.find(
                            (val) =>
                                val.section.id === filter.sectionId && val.question.id === filter.questionId
                        )?.value;
                        return filter.values.includes(caseReportValue as any);
                    };
                }),
                ...dateRangeFilters.map((filter) => {
                    return (caseReport: CaseReportForm) => {
                        if (filter.values.startDate == null || filter.values.endDate == null) {
                            return true;
                        }

                        const caseReportValue = caseReport.values.find(
                            (val) =>
                                val.section.id === filter.sectionId && val.question.id === filter.questionId
                        )?.value;

                        if (caseReportValue == null || caseReportValue === '') {
                            return false;
                        }

                        const isRelevant =
                            moment(caseReportValue).isSameOrBefore(moment(filter.values.endDate)) &&
                            moment(caseReportValue).isSameOrAfter(moment(filter.values.startDate));

                        return isRelevant;
                    };
                }),
            ],
        };
        return currentFilters;
    }, [selectFilters, dateRangeFilters]);

    const columns = useMemo(() => {
        const list: Array<TableColumnConfig<CaseReportForm>> = [
            {
                id: 'case',
                headerTitle: t('cases.internal_id'),
                width: 'minmax(max-content, 1fr)',
                isSearchable: true,
                isSortable: true,
                getValue: (caseReport) => caseReport.case.internalId,
                getDisplayedValue: (caseReport) => {
                    const url = `/${Routes.CASES}/${caseReport.case.id}/${Routes.CASE_FORMS}/${caseReport.id}`;
                    return (
                        <CaseInternalIdContainer to={url}>
                            <AiFillFolder />
                            {caseReport.case.internalId}
                        </CaseInternalIdContainer>
                    );
                },
            },
            {
                id: 'client',
                headerTitle: t('generic.client'),
                width: 'minmax(max-content, 1fr)',
                isSearchable: true,
                getValue: (caseReport) => {
                    if (caseReport.client == null) {
                        return '';
                    } else {
                        return `${caseReport.client.firstName} ${caseReport.client.lastName}`;
                    }
                },
                isSortable: true,
            },
        ];

        if (report != null) {
            for (const field of report.fields.sort((a, b) => a.index - b.index)) {
                list.push({
                    id: `${field.section.id} ${field.question.id}`,
                    headerTitle: field.question.name,
                    width: 'max-content',
                    isCentered: true,
                    isSearchable: true,
                    isSortable: true,
                    getValue: (caseReport) => {
                        const value = caseReport.values.find(
                            (v) => v.section.id === field.section.id && v.question.id === field.question.id
                        );

                        if (value == null || value.value === '') {
                            return '-';
                        }

                        if (field.question.inputType === ServerFormQuestionInputType.SELECT_INPUT) {
                            const option = field.question.options!.find((i) => i.value === value.value);
                            return formatLocalizedText(option!.label, locale);
                        }

                        if (field.question.inputType === ServerFormQuestionInputType.DATE_INPUT) {
                            return moment(value.value).format('LL');
                        }

                        return value.value;
                    },
                    getSortValue: (caseReport) => {
                        const value = caseReport.values.find(
                            (v) => v.section.id === field.section.id && v.question.id === field.question.id
                        );

                        if (value == null || value.value === '') {
                            return 0;
                        }

                        if (field.question.inputType === ServerFormQuestionInputType.SELECT_INPUT) {
                            const option = field.question.options!.find((i) => i.value === value.value);
                            return formatLocalizedText(option!.label, locale);
                        }

                        if (field.question.inputType === ServerFormQuestionInputType.DATE_INPUT) {
                            return moment(value.value).toDate().getTime();
                        }

                        return value.value;
                    },
                });
            }
        }
        return list;
    }, [report, locale]);

    const table = useTable(report.forms ?? [], columns, { searchTerm: searchTerm, filters: tableFilters });

    return <Table {...table} isLoading={false} columns={table.displayedColumns} />;
};

export default ReportTable;
