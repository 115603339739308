import React from 'react';
import { Actions, ServerForm, ServerFormQuestionInputType } from '../../../../../../../types';
import { CustomActions } from '../../../../FormsList';
import { Container, Header } from './FormsListDetailsReport-styles';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    PrimaryContainedButton,
    Table,
    TableColumnConfig,
} from '../../../../../../../components';
import { useTable } from '../../../../../../../hooks';
import { useTranslation } from 'react-i18next';

type FormsListDetailsReportProps = {
    selectedForm: ServerForm;
    openModalHandler: (selectedAction: Actions | CustomActions, form?: ServerForm | undefined) => void;
};

const FormsListDetailsReport = ({ selectedForm, openModalHandler }: FormsListDetailsReportProps) => {
    const { t } = useTranslation();

    const columns: Array<TableColumnConfig<ServerForm['report']['fields'][number]>> = [
        {
            id: 'section',
            headerTitle: 'Section',
            getValue: (field) => field.section.name,
            width: '1fr',
        },
        {
            id: 'question',
            headerTitle: 'Question',
            getValue: (field) => field.question.name,
            width: '1fr',
        },
        {
            id: 'filter',
            headerTitle: t('generic.filter'),
            getValue: (field) =>
                field.question.inputType === ServerFormQuestionInputType.TEXT_INPUT
                    ? 'N/A'
                    : field.settings.useFilter
                    ? 'Yes'
                    : 'No',
            width: '1fr',
        },
    ];

    const { data, displayedColumns } = useTable(selectedForm.report.fields, columns);

    return (
        <Container>
            <AccordionContainer triggerUpdate={selectedForm}>
                <Accordion level={0} isToggled isDisabled>
                    <AccordionHeader>
                        <AccordionHeaderTitle>
                            <Header>
                                ({selectedForm.report.fields.length}) {t('generic.reports')}
                            </Header>
                        </AccordionHeaderTitle>
                        <AccordionHeaderContent>
                            <PrimaryContainedButton
                                onClick={() => openModalHandler(CustomActions.UPDATE_REPORT)}
                            >
                                {t('forms.main.modals.reports.title')}
                            </PrimaryContainedButton>
                        </AccordionHeaderContent>
                    </AccordionHeader>
                    <AccordionBody>
                        <Table columns={displayedColumns} data={data} />
                    </AccordionBody>
                </Accordion>
            </AccordionContainer>
        </Container>
    );
};

export default FormsListDetailsReport;
