import React, { useRef, useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main styles
import 'react-date-range/dist/theme/default.css'; // Default theme
import {
    Container,
    DateRange,
    InputInnerLabel,
    TrailingIconContainer,
    Label,
    Clear,
} from './DateRangeInput-styles';
import { InputMode } from '../Inputs-types';
import { BsCalendar } from 'react-icons/bs';
import { format } from 'date-fns';
import { useOnClickOutside } from 'usehooks-ts';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export type DateRangeInputProps = {
    value: { startDate: Date | undefined; endDate: Date | undefined };
    onChange(value: { startDate: Date | undefined; endDate: Date | undefined }): void;
    mode?: InputMode;
    label?: string;
};

const DateRangeInput = ({ value, onChange, label, mode = InputMode.STAND_ALONE }: DateRangeInputProps) => {
    const [isOpened, setIsOpened] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const handleSelect = (ranges: Range) => {
        onChange({
            startDate: ranges.startDate,
            endDate: ranges.endDate,
        });
    };

    const togglePicker = () => {
        setIsOpened((prev) => !prev);
    };

    const formatRange = () => {
        if (value.startDate && value.endDate) {
            return `${format(value.startDate, 'MM-dd-yyyy')} - ${format(value.endDate, 'MM-dd-yyyy')}`;
        }
        return '';
    };

    useOnClickOutside(ref, () => {
        setIsOpened(false);
    });

    return (
        <Container ref={ref} mode={mode}>
            <Label onClick={togglePicker}>
                {label != null && <InputInnerLabel>{label}:</InputInnerLabel>}

                {formatRange()}
                <TrailingIconContainer className="input-container__trailing-icon">
                    {value.startDate != null && value.endDate != null ? (
                        <Clear
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange({ startDate: undefined, endDate: undefined });
                            }}
                        >
                            <AiOutlineCloseCircle />
                        </Clear>
                    ) : (
                        <BsCalendar />
                    )}
                </TrailingIconContainer>
            </Label>

            {isOpened === true && (
                <DateRange>
                    <DateRangePicker
                        ranges={[
                            {
                                startDate: value.startDate || new Date(),
                                endDate: value.endDate || new Date(),
                                key: 'selection',
                            },
                        ]}
                        onChange={(ranges) => handleSelect(ranges.selection)}
                        direction="vertical"
                        scroll={{ enabled: true }}
                    />
                </DateRange>
            )}
        </Container>
    );
};

export default DateRangeInput;
