import React, { useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Routes } from './dictionary';

// Route context
import { AuthenticatedRoute, ClientRoute, EmployeeRoute, UnauthenticatedRoute } from './context';

// pages
import {
    CaseAccessManagementPage,
    CaseClientsPage,
    CaseDocumentsPage,
    CaseEventsPage,
    CaseNewPage,
    CaseNotesPage,
    CaseOverviewPage,
    CasePages,
    CaseQuotePage,
    CasesListPage,
    CasesPages,
    CaseFormsPages,
    CaseFormsListPage,
    CaseFormsDetailsPage,
    CaseFormsFillOutPage,
    CategoriesPages,
    CategoriesListPage,
    CategoriesQuotesListPage,
    CategoriesFilesListPage,
    ClientsListPage,
    ClientsPages,
    EmployeesListPage,
    EmployeesPages,
    DashboardPage,
    LoginPage,
    OrganizationsPages,
    OrganizationsListPage,
    SettingsPages,
    UuidPage,
    RedirectToLoginPage,
    CaseDocumentsListPage,
    CaseDocumentsDetailsPage,
    FormsPages,
    FormsListPage,
    FormsSectionsListPage,
    FormsQuestionsListPage,
    FormsListTablePage,
    FormsListDetailsPage,
    ResetPasswordRequestPage,
    ResetPasswordConfirmPage,
    SettingsProfilePage,
    SettingsCredentialsPage,
    SettingsPreferencesPage,
    ReportsPages,
    ReportsListPage,
    ReportPage,
} from '../pages';
import { useLoggedInUser } from '../hooks';

const SHARED_ROUTES = [
    {
        element: <UuidPage />,
        path: Routes.UUID,
    },
    // *********************************************************
    // UNAUTHENTICATED ROUTES
    // *********************************************************
    {
        element: <UnauthenticatedRoute />,
        children: [
            {
                path: Routes.LOGIN,
                children: [
                    {
                        path: '',
                        element: <LoginPage />,
                    },
                    {
                        path: '*',
                        element: <LoginPage />,
                    },
                ],
            },
            {
                path: Routes.RESET_PASSWORD_REQUEST,
                element: <ResetPasswordRequestPage />,
            },
            {
                path: Routes.RESET_PASSWORD_CONFIRM,
                element: <ResetPasswordConfirmPage />,
            },
        ],
    },
    // *********************************************************
    // AUTHENTICATED ROUTES
    // *********************************************************
    {
        element: <AuthenticatedRoute />,
        children: [
            {
                path: Routes.SETTINGS,
                element: <SettingsPages />,
                children: [
                    {
                        path: '',
                        element: <SettingsProfilePage />,
                    },
                    {
                        path: Routes.SETTINGS_PROFILE,
                        element: <SettingsProfilePage />,
                    },
                    {
                        path: Routes.SETTINGS_CREDENTIALS,
                        element: <SettingsCredentialsPage />,
                    },
                    {
                        path: Routes.SETTINGS_PREFERENCES,
                        element: <SettingsPreferencesPage />,
                    },
                ],
            },
            {
                path: '',
                element: <DashboardPage />,
            },
        ],
    },
];

const EMPLOYEES_ROUTES = [
    {
        element: <EmployeeRoute />,
        children: [
            {
                element: <EmployeesPages />,
                path: Routes.EMPLOYEES,
                children: [
                    {
                        element: <EmployeesListPage />,
                        path: Routes.EMPLOYEES_LIST,
                    },
                ],
            },
            {
                element: <ClientsPages />,
                path: Routes.CLIENTS,
                children: [
                    {
                        element: <ClientsListPage />,
                        path: Routes.CLIENTS_LIST,
                    },
                ],
            },
            {
                element: <OrganizationsPages />,
                path: Routes.ORGANIZATIONS,
                children: [
                    {
                        element: <OrganizationsListPage />,
                        path: Routes.ORGANIZATIONS_LIST,
                    },
                ],
            },
            {
                element: <CasesPages />,
                path: Routes.CASES,
                children: [
                    {
                        element: <CasesListPage />,
                        path: Routes.CASES_LIST,
                    },
                    {
                        element: <CaseNewPage />,
                        path: Routes.CASES_NEW,
                    },
                    {
                        element: <CasePages />,
                        path: ':id',
                        children: [
                            {
                                element: <CaseOverviewPage />,
                                path: Routes.CASE_OVERVIEW,
                            },
                            {
                                element: <CaseQuotePage />,
                                path: Routes.CASE_QUOTE,
                            },
                            {
                                element: <CaseDocumentsPage />,
                                path: Routes.CASE_DOCUMENTS,
                                children: [
                                    {
                                        element: <CaseDocumentsListPage />,
                                        path: Routes.CASE_DOCUMENTS_LIST,
                                    },
                                    {
                                        element: <CaseDocumentsDetailsPage />,
                                        path: ':folder_id',
                                    },
                                ],
                            },
                            {
                                element: <CaseClientsPage />,
                                path: Routes.CASE_CLIENTS,
                            },
                            {
                                element: <CaseAccessManagementPage />,
                                path: Routes.CASE_ACCESS_MANAGEMENT,
                            },
                            {
                                element: <CaseNotesPage />,
                                path: Routes.CASE_NOTES,
                            },
                            {
                                element: <CaseEventsPage />,
                                path: Routes.CASE_EVENTS,
                            },
                            {
                                element: <CaseFormsPages />,
                                path: Routes.CASE_FORMS,
                                children: [
                                    {
                                        element: <CaseFormsListPage />,
                                        path: Routes.CASE_FORMS_LIST,
                                    },
                                    {
                                        element: <CaseFormsDetailsPage />,
                                        path: ':form_id',
                                        children: [
                                            {
                                                element: <CaseFormsFillOutPage />,
                                                path: Routes.CASE_FORMS_FILL,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                element: <Navigate to={Routes.CASE_OVERVIEW} />,
                                path: '*',
                            },
                        ],
                    },
                ],
            },
            {
                element: <CategoriesPages />,
                path: Routes.CATEGORIES,
                children: [
                    {
                        element: <CategoriesListPage />,
                        path: Routes.CATEGORIES_LIST,
                    },
                    {
                        element: <CategoriesQuotesListPage />,
                        path: Routes.CATEGORIES_QUOTES_LIST,
                    },
                    {
                        element: <CategoriesFilesListPage />,
                        path: Routes.CATEGORIES_FILES_LIST,
                    },
                ],
            },
            {
                element: <FormsPages />,
                path: Routes.FORMS,
                children: [
                    {
                        element: <FormsListPage />,
                        path: Routes.FORMS_LIST,
                        children: [
                            {
                                path: Routes.FORMS_LIST_TABLE,
                                element: <FormsListTablePage />,
                            },
                            {
                                path: ':form_id',
                                element: <FormsListDetailsPage />,
                            },
                        ],
                    },
                    {
                        element: <FormsSectionsListPage />,
                        path: Routes.FORMS_SECTIONS_LIST,
                    },
                    {
                        element: <FormsQuestionsListPage />,
                        path: Routes.FORMS_QUESTIONS_LIST,
                    },
                ],
            },
            {
                element: <ReportsPages />,
                path: Routes.REPORTS,
                children: [
                    {
                        element: <ReportsListPage />,
                        path: Routes.REPORTS_LIST,
                    },
                    {
                        element: <ReportPage />,
                        path: ':form_id',
                    },
                ],
            },
        ],
    },
];

const CLIENTS_ROUTES = [
    {
        element: <ClientRoute />,
        children: [
            {
                element: <CasesPages />,
                path: Routes.CASES,
                children: [
                    {
                        element: <CasePages />,
                        path: ':id',
                        children: [
                            {
                                element: <CaseOverviewPage />,
                                path: Routes.CASE_OVERVIEW,
                            },
                            // {
                            //     element: <CaseQuotePage />,
                            //     path: Routes.CASE_QUOTE,
                            // },
                            {
                                element: <CaseDocumentsPage />,
                                path: Routes.CASE_DOCUMENTS,
                                children: [
                                    {
                                        element: <CaseDocumentsListPage />,
                                        path: Routes.CASE_DOCUMENTS_LIST,
                                    },
                                    {
                                        element: <CaseDocumentsDetailsPage />,
                                        path: ':folder_id',
                                    },
                                ],
                            },
                            {
                                element: <CaseNotesPage />,
                                path: Routes.CASE_NOTES,
                            },
                            {
                                element: <CaseEventsPage />,
                                path: Routes.CASE_EVENTS,
                            },
                            {
                                element: <CaseFormsPages />,
                                path: Routes.CASE_FORMS,
                                children: [
                                    {
                                        element: <CaseFormsListPage />,
                                        path: Routes.CASE_FORMS_LIST,
                                    },
                                    {
                                        element: <CaseFormsDetailsPage />,
                                        path: ':form_id',
                                        children: [
                                            {
                                                element: <CaseFormsFillOutPage />,
                                                path: Routes.CASE_FORMS_FILL,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                element: <Navigate to={Routes.CASE_OVERVIEW} />,
                                path: '*',
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

const RoutesComponent = () => {
    const user = useLoggedInUser();

    const routes = useMemo(() => {
        const array = [...SHARED_ROUTES];

        if (user.isEmployee() === true) {
            array[array.length - 1].children?.push(...(EMPLOYEES_ROUTES as any));
        }

        if (user.isClient() === true) {
            array[array.length - 1].children?.push(...(CLIENTS_ROUTES as any));
        }

        array.push({
            element: user.isLoggedIn() === true ? <Navigate to="/" /> : <RedirectToLoginPage />,
            path: '*' as Routes,
        });

        return array;
    }, [user]);

    return useRoutes(routes);
};

export default RoutesComponent;
