import React from 'react';
import DatePicker from 'react-datepicker';

// styles
import { Container, InputInnerLabel, TrailingIconContainer } from './DateInput-styles';
import 'react-datepicker/dist/react-datepicker.css';
import { InputMode } from '../Inputs-types';
import { BsCalendar } from 'react-icons/bs';

export type DateInputProps = {
    value: Date | undefined;
    onChange(value: Date | null): void;
    mode?: InputMode;
    label?: string;
    placeholder?: string;
};

const DateInput = ({ value, onChange, label, mode = InputMode.STAND_ALONE, placeholder }: DateInputProps) => {
    return (
        <Container mode={mode}>
            {label != null && <InputInnerLabel>{label}:</InputInnerLabel>}
            <DatePicker selected={value} onChange={onChange} placeholderText={placeholder} />
            <TrailingIconContainer className="input-container__trailing-icon">
                <BsCalendar />
            </TrailingIconContainer>
        </Container>
    );
};

export default DateInput;
