import styled from 'styled-components';

export const List = styled.div`
    width: 100%;
`;

export const Field = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.box.spacing.md};

    > div:first-child {
        width: 70%;
    }

    > div:last-child {
        width: 30%;
    }
`;

export const RemoveField = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.sm};

    svg {
        font-size: ${({ theme }) => theme.font.size.md};
        color: ${({ theme }) => theme.colors.danger.main};
        cursor: pointer;
    }
`;

export const AddField = styled.div`
    margin: ${({ theme }) => theme.box.spacing.md} 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const AddFieldText = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: underline;
    cursor: pointer;
`;
